import { Stack } from '@mui/material';
import logo from '../images/logo.svg';
import title from '../images/title.svg';

export default function Logo(props) {
    return (
        <Stack spacing={1}>
            <img src={logo} alt='Logo' title='MyiPantry' style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
            <img src={title} alt='Title' style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', filter: !props.light ? 'invert(7%) sepia(2%) saturate(2230%) hue-rotate(87deg) brightness(94%) contrast(91%)' : 'invert(77%) sepia(6%) saturate(117%) hue-rotate(42deg) brightness(103%) contrast(92%)' }} />
        </Stack>
    );
}