import { useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { Box, Grid, IconButton, Stack, Typography, Link, Button } from '@mui/material';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import CssBaseline from "@mui/material/CssBaseline";
import useMediaQuery from '@mui/material/useMediaQuery';
import Cookies from 'universal-cookie';
import Logo from './components/Logo.js';
import { generateTheme } from './theme/theme.js';
import Positioned from './Positioned.js';

function Main() {
  const cookies = new Cookies();
  const mode = cookies.get('theme');
  const preferedMode = useMediaQuery('(prefers-color-scheme: dark)');

  const [theme, setTheme] = useState(mode != null ? convertToBool(mode) : preferedMode);

  const darkTheme = generateTheme(theme);

  function convertToBool(bool) {
    return (bool === 'true')
  }

  function handleThemeChange() {
    setTheme(!theme);
    cookies.remove('theme', { path: '/' })
    cookies.set('theme', !theme, { path: '/' });
  }

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Box sx={{ backgroundColor: theme ? darkTheme.palette.surface.dark : darkTheme.palette.surface.main }}>
        <Positioned top='25px' right='10px'>
          <IconButton onClick={handleThemeChange}>
            {theme ? <DarkModeIcon /> : <LightModeIcon />}
          </IconButton>
        </Positioned>
        <Grid container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: '100vh' }}>
          {/* <Card>
          <Padding padding={[100, 50, 100, 50]}> */}
          <Stack spacing={0}>
            <Logo light={theme} />
            {/* <img src={logo} alt='Logo' title='MyiPantry' style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
            <img src={title} alt='Title' style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', color: 'white' }} /> */}
            <Typography fontFamily={'PatrickHand'} fontSize={'32px'} textAlign={'center'} color={theme ? darkTheme.palette.onSurface.dark : darkTheme.palette.onSurface.main}>Under Construction (:</Typography>
            <Button sx={{ borderRadius: '25px', }} component={Link} href='https://app.myipantry.com' variant='contained'>
              Try the beta now
            </Button>
            {/* <Grid container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{
              transform: 'translate(0px, -50px)'
            }}>
            <Stack direction={'row'}>
                  <LightModeIcon />
                  <Switch
                    checked={theme}
                    color='success'
                    onChange={handleThemeChange}
                    sx={{
                      transform: 'translate(0px, -5px)'
                    }} />
                  <DarkModeIcon />
                </Stack>
          </Grid> */}
          </Stack>
          {/* </Padding>
        </Card> */}
        </Grid>
      </Box>
    </ThemeProvider>
  );
}

export default Main;
