import { createTheme } from '@mui/material/styles';
import PatrickHand from '../fonts/PatrickHand-Regular.ttf';

export const generateTheme = (darkMode) => createTheme({
    palette: {
        mode: darkMode ? 'dark' : 'light',
        primary: {
            main: '#BBE1C3',
        },
        secondary: {
            main: '#819684',
        },
        error: {
            main: '#ff5449',
        },
        info: {
            main: '#6d97a3',
        },

        primaryContainer: {
            light: '#97F7B7',
            main: '#97F7B7',
            dark: '#00522D',
            contrastText: 'rgba(0, 0, 0, 0.87)',
        },
        onPrimary: {
            light: '#FFFFFF',
            main: '#FFFFFF',
            dark: '#00391D',
            contrastText: 'rgba(0, 0, 0, 0.87)',
        },
        onPrimaryContainer: {
            light: '#00210F',
            main: '#00210F',
            dark: '#97F7B7',
            contrastText: 'rgba(0, 0, 0, 0.87)',
        },

        secondaryContainer: {
            light: '#81F8D1',
            main: '#81F8D1',
            dark: '#00513E',
            contrastText: 'rgba(0, 0, 0, 0.87)',
        },
        onSecondary: {
            light: '#FFFFFF',
            main: '#FFFFFF',
            dark: '#00382A',
            contrastText: 'rgba(0, 0, 0, 0.87)',
        },
        onSecondaryContainer: {
            light: '#002117',
            main: '#002117',
            dark: '#81F8D1',
            contrastText: 'rgba(0, 0, 0, 0.87)',
        },

        tertiary: {
            light: '#336B23',
            main: '#336B23',
            dark: '#99D781',
            contrastText: 'rgba(0, 0, 0, 0.87)',
        },
        tertiaryContainer: {
            light: '#B4F39A',
            main: '#B4F39A',
            dark: '#1B520B',
            contrastText: 'rgba(0, 0, 0, 0.87)',
        },
        onTertiary: {
            light: '#FFFFFF',
            main: '#FFFFFF',
            dark: '#093900',
            contrastText: 'rgba(0, 0, 0, 0.87)',
        },
        onTertiaryContainer: {
            light: '#032100',
            main: '#032100',
            dark: '#B4F39A',
            contrastText: 'rgba(0, 0, 0, 0.87)',
        },

        surface: {
            light: '#FBFDF8',
            main: '#FBFDF8',
            dark: '#191C1A',
            contrastText: 'rgba(0, 0, 0, 0.87)',
        },
        onSurface: {
            light: '#191C1A',
            main: '#191C1A',
            dark: '#C5C7C2',
            contrastText: 'rgba(0, 0, 0, 0.87)',
        },
        surfaceVariant: {
            light: '#DCE5DB',
            main: '#DCE5DB',
            dark: '#414942',
            contrastText: 'rgba(0, 0, 0, 0.87)',
        },
        onSurfaceVariant: {
            light: '#414942',
            main: '#414942',
            dark: '#C0C9BF',
            contrastText: 'rgba(0, 0, 0, 0.87)',
        },

        inverseSurface: {
            light: '#2E312E',
            main: '#2E312E',
            dark: '#E1E3DE',
            contrastText: 'rgba(0, 0, 0, 0.87)',
        },
        onInverseSurface: {
            light: '#F0F1EC',
            main: '#F0F1EC',
            dark: '#191C1A',
            contrastText: 'rgba(0, 0, 0, 0.87)',
        },
        inversePrimary: {
            light: '#7BDA9C',
            main: '#7BDA9C',
            dark: '#006D3D',
            contrastText: 'rgba(0, 0, 0, 0.87)',
        },

        background: {
            light: '#FBFDF8',
            main: '#FBFDF8',
            dark: '#191C1A',
            contrastText: 'rgba(0, 0, 0, 0.87)',
        },
        onBackground: {
            light: '#191C1A',
            main: '#191C1A',
            dark: '#E1E3DE',
            contrastText: 'rgba(0, 0, 0, 0.87)',
        },
        outline: {
            light: '#717971',
            main: '#717971',
            dark: '#8B938A',
            contrastText: 'rgba(0, 0, 0, 0.87)',
        },
    },
    typography: {
        fontFamily: 'PatrickHand, Arial',
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
            @font-face {
                font-family: "PatrickHand";
                src: local("PatrickHand"), url(${PatrickHand}) format("opentype");
                font-style: Regular;
            }
            `,
        }
    }
});