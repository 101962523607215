export default function Positioned(props) {
    return (
        <div style={{
            position: 'fixed',
            top: `${props.top}`,
            left: `${props.left}`,
            right: `${props.right}`,
            bottom: `${props.bottom}`,
            transform: `translate(-50%, -50%)`
        }}>
            {props.children}
        </div>
    );
}